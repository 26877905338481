import React from 'react';
import { Link } from 'gatsby';
import Image from "../components/image";
import "typeface-montserrat-subrayada";

import LogoImage from '../images/logo_l2.png';
import LogoImage2 from '../images/logo_b.png';
import LogoImage3 from '../images/logo_c.png';
import Syoei1 from '../images/syoei1.png';
import Syoei2 from '../images/syoei2.png';
import Syoei3 from '../images/syoei3.png';
import Footer from '../components/footer/footer'

const HomePage = (props: any) => {
  const { data } = props;


  return (
    <div>
 {/* <div style={{backgroundImage:"url('https://source.unsplash.com/featured/2000x2000/?globe')",height:"1000px",width:"100%"}}> */}
      <div>
        <div style={{position:"relative",height:"100%",zIndex:1}}>
          <div style={{
              top: "0",
              left: "0",
              width: "auto",
              height: "auto",
              minWidth: "100%",
              minHeight: "100%",
              maxWidth: "inherit",
          }}>
            <Image filename="top_l5.png"></Image>
          </div>
        </div>


        <div style={{borderColor:"5px", position:"absolute",margin:"-130% 0 0 10vw", width:"20vw",zIndex:5}}>
          <a href="#" style={{display:"block",zIndex:5}}>

          </a>
        </div>







        <div>
          <div style={{position:"absolute",margin:"-130% 0 0 5vw"}}>
            <Link to="/themeset">
            <img src={LogoImage}  style={{position:"absolute",fontSize:"3rem",width:"45vw",color:"white", textShadow:"20px",zIndex:5,fontFamily: "montserrat-subrayada"}}/>
            </Link>
          </div>


          <div style={{position:"absolute",margin:"-95% 0 0 5vw"}}>
            <Link to="https://www.teikokushoin.co.jp/">
            <img src={Syoei1}  style={{position:"absolute",fontSize:"3rem",width:"9vw",color:"white", textShadow:"20px",zIndex:5,fontFamily: "montserrat-subrayada"}}/>
            </Link>
          </div>
          <div style={{position:"absolute",margin:"-93% 0 0 16vw"}}>
            <Link to="https://www.teikokushoin.co.jp/">
            <img src={Syoei2}  style={{position:"absolute",fontSize:"3rem",width:"9vw",color:"white", textShadow:"20px",zIndex:5,fontFamily: "montserrat-subrayada"}}/>
            </Link>
          </div>
          <div style={{position:"absolute",margin:"-93% 0 0 27vw"}}>
            <Link to="https://www.teikokushoin.co.jp/">
            <img src={Syoei3}  style={{position:"absolute",fontSize:"3rem",width:"9vw",color:"white", textShadow:"20px",zIndex:5,fontFamily: "montserrat-subrayada"}}/>
            </Link>
          </div>
          {/* <div style={{position:"absolute",margin:"-50% 0 0 10vw"}}>
            <h1 style={{position:"absolute",fontSize:"3rem",width:"80vw",color:"white", textShadow:"20px",zIndex:5,fontFamily: "montserrat-subrayada"}}>地域見える化GIS ジオグラフ<br/>by 帝国書院</h1>
          </div> */}
        </div>
        <div>
          {/* <div style={{position:"absolute",margin:"-30% 0 0 10vw",color:"blue"}}>
            <Link to="/themeset" style={{position:"absolute",fontSize:"3rem",width:"80vw",color:"darkblue", backgroundColor:"white",textShadow:"20px",zIndex:5}}>ジオグラフを使う</Link>
          </div> */}

<div style={{position:"absolute",margin:"-130% 0 0 75vw"}}>
            <Link to="/fsaas/#t">
            <img src={LogoImage2}  style={{position:"absolute",fontSize:"3rem",width:"17vw",color:"white", textShadow:"20px",zIndex:5,fontFamily: "montserrat-subrayada"}}/>
            </Link>
          </div>

<div style={{position:"absolute",margin:"-130% 0 0 55vw"}}>
            <Link to="/themeset">
            <img src={LogoImage3}  style={{position:"absolute",fontSize:"3rem",width:"17vw",color:"white", textShadow:"20px",zIndex:5,fontFamily: "montserrat-subrayada"}}/>
            </Link>
          </div>

          {/* <div>
            <br/>
            <br/>
            <br/>
            <div style={{backgroundColor:"darkblue", color:"white", width:"30%", height:"3rem", fontSize:"2rem", textAlign:"center",margin:"1rem",zIndex:5}}>
              <Link to="/themeset" style={{color:"white", width:"8rem", height:"3rem", fontSize:"2rem",margin:"0.5rem",zIndex:5}}>SAASを使う</Link>
            </div>
          </div> */}
                      {/* <Image filename="teikoku100.png"></Image> */}

        </div>
      </div>
      <Footer>
          Copyright &copy; {new Date().getFullYear()}
          <a href="https://www.teikokushoin.co.jp/"> 帝国書院</a>
          <span> ＆ </span>
          <a href="https://www.kashika.or.jp/"> 一般社団法人 都市構造可視化推進機構</a>
        </Footer>
    </div>
  );
};

export default HomePage;

